import React, { useMemo, useEffect } from 'react';
import { hooks } from '@front/volcanion'
import info from '../info'
import config from '../constants'
import StorageManager from '@front/squirtle/services/storage';
import Provider from '@front/volcanion/provider'
import { ThemeProvider } from '@mui/material/styles'
import IdentityUtils from '@front/squirtle/utils/identity'
import { Loader, SnackbarProvider, SVG, Image, GIF } from '@front/squirtle'


import useBlobHandler from '@front/squirtle/hooks/useBlobHandler'
import useNotification from '@front/squirtle/hooks/useNotification'
import useRouter from '@front/squirtle/components/Router/useRouter'

import getTranslations from './translations'
import { I18n } from '@front/volcanion'

I18n.setI18nConfig(getTranslations)

import Pages from './pages'

const Main = () => {
  const identity = hooks.useIdentity()
  const theme = useMemo(() => IdentityUtils.getTheme(identity, 'light'), [identity])
  const CLIENT_COMMERCIAL_NAME = hooks.useRelayConstant('CLIENT_COMMERCIAL_NAME')

  useEffect(() => {
    document.title = CLIENT_COMMERCIAL_NAME
  }, [CLIENT_COMMERCIAL_NAME])

  return (
    <Loader isLoading={!identity} size={200} _loader={{ style: { objectFit: 'contain' } }}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Pages />
        </SnackbarProvider>
      </ThemeProvider>
    </Loader>
  )
}

const App = () =>
  <Provider
    storage={{ whitelist: [], manager: StorageManager }}
    relay={{ info, config }}
    model={{
      auto_load: []
    }}
    global={{ persist: ['persist'] }}
    main={{
      useRouter,
      useNotification,
      useBlobHandler,
      ImageRenderer: Image,
      SVGRenderer: SVG,
      GIFRenderer: GIF
    }}
  >
    <Main />
  </Provider>
export default React.memo(App);
